
import './Notes.css'
import {useState , useEffect} from 'react'
import Note from './Note'
import Modal from 'react-modal'
import EditNote from './EditNote'
import axios from 'axios'


function Notes (props) {
const [modal,Setmodal] = useState(false)
const [add,Setadd] = useState(false)
const [title,Settitle] = useState('')
const [desc,Setdesc] = useState('')
const [notatka,Setnotatka] = useState([])

const [editNote,Seteditnote] = useState ({})

async function fetchNotes ()  {
    const res =  await axios.get('http://mieszkaniaskawina.net.pl/notes')
    console.log(res.data)
    const notes = res.data
    Setnotatka(notes)

}
const res =   axios.get('http://mieszkaniaskawina.net.pl/notes')
const notes = res.data
useEffect(() => {
  fetchNotes();
}, [notes]);

 function deleteNote (id) {
 console.log(id)
    const notatkas = [...notatka].filter(note => note._id !== id)
     Setnotatka(notatkas)
}
const openAdd = () => {
    Setadd(!add)
}
async function AddNote  (){

   const res = await axios.post('http://mieszkaniaskawina.net.pl/notes',{title:title,body:desc})
   const newNote =res.data
   const nota = [...notatka,newNote]
   Setnotatka(nota)
    Settitle('')
    Setdesc('')
    
}

const ToglleModal = () =>{
    Setmodal(!modal)

}

const EditNoteHandler = (note) =>{
    Setmodal(!modal)
    Seteditnote(note)
}


const Editnote = (note) =>{
    const notes = [...notatka]
    const index = notes.findIndex(x => x._id === note._id)
    notes[index] = note
    Setnotatka(notes)
    Setmodal(false)

}


const customStyles = {
    content: {
    height:'300px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
    return( <div className='lolek'>
        <Modal
isOpen={modal} contentLabel='Edytuj Notatkę' style={
 customStyles
  }>
<EditNote name={editNote.title} description={editNote.body} id ={editNote._id} onEdit = {(note) => Editnote(note)}/>
<button onClick={ToglleModal}>Anuluj</button>
</Modal>
        <p>Notatki :</p>
      {
        notatka.map(notatka => (<Note key={notatka._id} name={notatka.title} description={notatka.body} id={notatka._id} deleteNote = {(id) => deleteNote(id)} EditNoteHandler={(note) => EditNoteHandler(note)} />))
      }

      <div className='addnote'><button className='butt' onClick={openAdd}>Dodawanie nowej notatki</button>
      {  add ?  (<>
      <label>Tytuł:</label><input value={title} type='text' onChange={e => Settitle(e.target.value)}></input>
      <label>Opis:</label><input value={desc} type='text' onChange={e => Setdesc(e.target.value)}></input> 
      <button onClick={() => AddNote()}>Dodaj</button></>): null}
      
      
      </div>
    </div>)
}

export default Notes