
import './Note.css'
import {useState} from 'react'


function Note (props) {





    return( <div className='notesmain'>
       
    <p>Zadanie: {props.name} </p>  
    <p>Opis: {props.description}</p>
    <div className='buttony'>
    <button className='butt' onClick={() => props.EditNoteHandler({title:props.name, body:props.description, _id:props.id})}>Edytuj</button><button className='butt' onClick={(id) => props.deleteNote(props.id)}>Usuń</button>
    </div>
    </div>)
}

export default Note