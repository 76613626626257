
import './App.css';
import Notes from './Components/Notes';

function App() {
  return (
    <div className="App">
    <Notes/>
    </div>
  );
}

export default App;
